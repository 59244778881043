.cInput {
  padding: 0.5rem 1rem;
  width: 15rem;
  border: 1px solid $THEME_COLOR;
  border-radius: 0.2rem;
  background-color: #f5f6f5;
}

.fullLengthBtn {
  width: 100%;
  height: 3rem;
  background-color: $THEME_COLOR;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
}

.medButton {
  width: 15rem;
  height: 2.5rem;
  background-color: $THEME_COLOR !important;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
}

.smButton {
  width: 7.5rem;
  height: 2.5rem;
  background-color: $THEME_COLOR !important;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
}

.cFWButton {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: $THEME_COLOR;
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
}

.cSmButton {
  min-width: 4rem;
  background-color: $THEME_COLOR;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  color: white;
  cursor: pointer;
}

.cInputWithLabel {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  label {
    color: $THEME_COLOR;
    font-size: 0.8rem;
  }
  input {
    background-color: #f6f4fa;
    border: none;
    outline: none;
    height: 2rem;
    border-radius: 0.25rem;
    padding: 0 1rem;
  }
}

.cSelectWithLabel {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  label {
    color: $THEME_COLOR;
    font-size: 0.8rem;
  }
  select {
    background-color: #f6f4fa;
    border: none;
    outline: none;
    height: 2rem;
    border-radius: 0.25rem;
    padding: 0 1rem;
  }
}

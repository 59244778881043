.form-AContainer {
  padding-top: 4.5rem;
  background-color: white;
  padding-bottom: 3rem;
  @media (max-width: 450px) {
    text-align: center;
  }
  &__image {
    width: 100px;
    height: 100px;
    margin: 1rem;
    background-color: lightgray;
  }
  &__form {
    padding: 0 2rem;
    text-align: center;
    color: gray;
    margin-top: -4rem;
    @media (max-width: 450px) {
      margin-top: 0;
      padding: 0 1rem;
    }
    h2 {
      font-weight: 500;
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
    &__formBody {
      border: 3px solid gray;
      height: 700px;
      padding: 2rem 2rem 0 2rem;
      margin: 0 1rem;
      @media (max-width: 768px) {
        padding: 1rem 1rem 0 1rem;
        margin: 0;
      }
      &__addIcon {
        float: left;
        margin-top: 0.5rem;
      }
      &__header {
        display: flex;
        &__heading-1 {
          font-size: 1.8rem;
          width: 92%;
          text-align: left;
          border-bottom: 3px solid lightgray;
          padding-bottom: 0.5rem;
          margin-right: 1rem;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          @media (max-width: 768px) {
            font-size: 1.5rem;
          }
        }
        &__border {
          border: 1px solid lightgray;
        }
        &__heading-2 {
          font-size: 1.8rem;
          width: 8%;
          border-bottom: 3px solid lightgray;
          padding-bottom: 0.5rem;
          margin-left: 1rem;

          height: 40px;

          @media (max-width: 768px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

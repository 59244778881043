.trainingPortfolio {
  padding: 1.5rem;
  padding-top: 5.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;

  h1,
  h2 {
    text-align: center;
  }
  h2 {
    margin-top: 1rem;
  }

  &__form {
    margin-top: 3rem;
    max-width: 50rem;
    width: 100%;

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__left {
        width: 35%;
      }

      &__right {
        flex-grow: 1;
        &__upper {
          margin-top: 1rem;
          hr {
            width: 35rem;
            height: 0.1rem;
            background-color: black;
          }
        }

        &__lower {
          font-size: 0.5rem;
          margin-top: 0.35rem;

          .biggerLabel {
            font-size: 0.7rem;
          }

          .smallLabel {
            font-size: 0.4rem;
          }
        }
      }
    }
  }

  &__smallDashes{
    width: 40%;
    display: flex;
    justify-content: space-between;

    .from{
      width: 40%;
    }

    .until{
      width: 40%;
    }
  }

  &__bottom-text{
    width:100%;
    display: flex;
    flex-direction: column;
  }

  &__compitency-button{
    button{
      width: 100%;
      background-color: transparent;
      height: 40px;
      border-radius:10px;
      border: 1px solid black;
    }
  }

  &__bottom-buttons{
    margin-top: 20px;
    div{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    button{
      width: 47%;
      height: 90px;
      margin-top: 10px;
      border-radius: 8px;
      border: none;
      background-color: #1D98D7;
      color: white;
    }
  }
   &__bottom-table-container{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    &__content{
      display: flex;
    }
    button{
      width: 4rem;
      height: 1.5rem;
      border: none;
      border-radius: 10px;
      color: white;
      background-color: #1D98D7;
    }

    &__table{
      table{
        margin-top: 2rem;
        width: 10rem;
        border: 1px solid black;
        border-collapse: collapse;
        th, td{
            padding: 0.5rem;
            text-align: center;
            border: 1px solid black;
            font-size: 0.5rem;
            }
        }
    }
   }
}

.teachContainer {
  padding-top: 6.2rem;
  // background-color: #f3f3f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__students {
    width: 100%;
    min-height: 50vh;
  }

  &__options {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
    cursor: pointer;
  }

  &__container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    font-size: 0.8rem;

    @media (max-width: 1200px) {
      width: 100%;
      padding: 0 2rem;
    }

    &__classes {
      display: flex;
      flex-direction: column;
      overflow: auto;

      table {
        width: 100%;
        background-color: #fafafa;
        border-spacing: 0;

        th,
        td {
          width: 25%;
          text-align: start;
          padding: 0.75rem 0.5rem;
          margin: 0;
          border-bottom: 1px solid black !important;
          &:first-child {
            width: 10%;
          }
          &:last-child {
            width: 15%;
          }
        }

        td {
          cursor: pointer;
        }
      }

      &__data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fafafa;
        padding: 1rem;
        border-bottom: 1px solid black;
        min-width: 40rem;
      }
    }
  }

  &__settings {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;

    &__card {
      height: 180px;
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: lightgray;
      margin-top: 5rem;

      p {
        color: gray;
        font-size: 2.5rem;
        font-weight: 500;
      }

      img {
        height: 70px;
        width: 70px;
      }
    }
  }
}

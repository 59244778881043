.nursingPhenomenaExplanation {
  // padding: 0 1.5rem;
  // padding-top: 4rem;
  display: flex;
  flex-flow: column;
  // justify-content: center;
  // align-items: center;
  letter-spacing: normal;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;


  &__body {
    // width: 80%;

    // @media (max-width:1200px) {
    //   width: 100%;
    // }
    &__header {
      // border-bottom: 1px solid gray;
      padding: 2rem;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        margin-right: 1rem;
      }

      h2 {
        color: gray;
      }
    }

    &__container {
      margin: 0 2rem;
      font-size: .8rem;
      p {
      border: 1px solid black;
      padding: .5rem;
      margin: 0 1rem;

      }
      &__link {
        margin: 2rem 0;
      a {
        text-decoration: none;
        color: white;
        background-color: #1E98D7;
        padding: 8px;
        border-radius: 5px;
      }
      }
    }
  }
}
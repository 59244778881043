.primary-heading {
  text-transform: uppercase;
  color: $THEME_COLOR;
}

.heading {
  h2,
  h3 {
    text-transform: uppercase;
    color: $THEME_COLOR;
  }
}

.centered {
  width: 100%;
  display: flex;
  justify-content: center;
}

.alignedRight {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

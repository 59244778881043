body {
  background-color: $BG_COLOR;
  letter-spacing: 0.1rem;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &:not(i) {
    font-family: "Plus Jakarta Sans", sans-serif !important;
  }
}

.logsReviews {
  // padding: 0 1.5rem;
  // padding-top: 4rem;
  display: flex;
  flex-flow: column;
  // justify-content: center;
  // align-items: center;
  letter-spacing: normal;
  font-size: .8rem;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;

  &__body {
    &__header {
      border-bottom: 1px solid gray;
      padding: 1.5rem 2rem;
      display: flex;
      justify-content: space-between;

      &__logo {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          margin-right: 1rem;
        }

        h1 {
          color: gray;
        }
      }
    }

    &__container {
      margin: 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        display: flex;
        flex-direction: column;
      }

      &__logs {
        text-align: center;
        width: 40%;

        @media (max-width: 750px) {
          width: 100%;
        }

        &__box {
          width: 100%;
          margin-top: 1rem;
          border: 1px solid black;
          height: 300px;
          overflow: auto;
        }
      }

      &__reviews {
        text-align: center;
        width: 40%;

        @media (max-width: 750px) {
          width: 100%;
          margin-top: 1rem;
        }

        &__box {
          width: 100%;
          margin-top: 1rem;
          border: 1px solid black;
          // width: 300px;
          height: 300px;
          overflow: auto;
        }
      }
    }
  }
}
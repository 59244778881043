.optionBox {
  max-width: 20rem;
  width: 100%;
  padding: 3rem 1rem;
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem 0;
  border: 1px solid gray;
  cursor: pointer;
  margin: 1rem;
  text-align: center;
  img {
    width: 5rem;
  }
}

.questionBox {
  max-width: 25rem;
  width: 100%;
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  border: 1px solid gray;
  min-height: 10rem;
  font-size: 0.8rem;
  text-align: justify;
  text-justify: inter-word;
  cursor: pointer;

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.customChecbox {
  display: flex;
  align-items: flex-start;
  margin-top: 0.3rem;
  font-size: 0.8rem;
  label {
    margin-left: 0.2rem;
  }
}

.adminPage {
  width: 100%;
  height: 100vh;
}

.sidebarItem {
  width: 100%;
  display: flex;
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
  border-radius: 0.5rem;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &--selected {
    background-color: rgba(255, 255, 255, 0.238);
    cursor: auto;
  }
  &__icon {
    width: 2rem;
  }
}

.sidebarAdminDrawer {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 1rem;
  height: 100%;
}

.checkedBoxWithDesc {
  width: 100%;
  max-width: 20rem;
  border: 1px solid black;
  padding: 1rem;
  min-height: 12rem;
  display: flex;
  flex-flow: column;
  cursor: pointer;

  &__checkbox {
    width: 100%;
    display: flex;
    height: 1rem;
    justify-content: flex-end;
  }

  &__desc {
    flex-grow: 1;
  }
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: -24px;
  animation: animloader 4s ease infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: #1e98d7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 24px #1e98d7, 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 24px #1e98d7, 24px 24px #1e98d7,
      24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 24px #1e98d7, 24px 24px #1e98d7, 24px 48px #1e98d7,
      0px 48px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 24px #1e98d7, 24px 24px #1e98d7, 24px 48px #1e98d7,
      0px 48px #1e98d7;
  }
  62% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px #1e98d7,
      24px 48px #1e98d7, 0px 48px #1e98d7;
  }
  75% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px #1e98d7, 0px 48px #1e98d7;
  }
  87% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px #1e98d7;
  }
  100% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0),
      24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}

.itemAContainer {
  padding: 4rem 2rem;
  background-color: white;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 400px) {
    padding: 4rem 1rem;
  }
  @media (max-width: 660px) {
    display: flex;
    justify-content: center;
  }
  &__box {
    min-width: 33.33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &__item {
      margin-top: 3rem;
      color: #788b92;
      &__heading {
        background-color: #788b92;
        color: lightgray;
        border-radius: 5px 5px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 40px;
        p {
          font-size: 1.5rem;
        }
      }
      &__body {
        border: 1.8rem solid #cfd8dd;
        width: 300px;
        height: 200px;
        &__contents {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.5rem;
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.registerAnalysis {
  padding: 0 1.5rem;
  padding-top: 7rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  letter-spacing: normal;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;


  &__body {
    width: 80%;

    @media (max-width:1200px) {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: #1E98D7;
        border-radius: 5px;
        padding: 10px;
        color: white;
        font-size: .8rem;
        outline: none;
        border: none;
      }
    }

    &__registerAnalysisTable {
      margin: 1rem 0;
      overflow: auto;

      &__table {
        border-collapse: collapse;

        width: 100%;
        min-width: 40rem;

        &__tableHead {
          &__row {
            border: 1px solid gray;

            &__head {
              font-size: .8rem;
              padding: .5rem;
              font-weight: normal;
              border: 1px solid gray;

            }
          }
        }

        &__tableBody {
          &__row {
            border: 1px solid gray;

            &__details {
              font-size: .8rem;
              padding: .5rem;
              text-align: center;
              border: 1px solid gray;

            }
          }
        }
      }
    }
    &__classAnalysisTable {
      overflow: auto;
    }
  }
}
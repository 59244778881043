.careForm {
  padding: 0 1.5rem;
  padding-top: 7rem;
  // display: flex;
  // flex-flow: column;
  // justify-content: center;
  // align-items: center;
  letter-spacing: normal;
  font-size: .8rem;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;

  &__header2 {
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;

    &__select {
      margin-left: 1rem;
      width: 50px;
      padding: 3px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
    // width: 80%;

    @media (max-width: 750px) {
      display: flex;
      justify-content: center;
    }
  }
}

.careFormCheckbox {
  border: 1px solid gray;
  width: 30%;
  min-width: 300px;
  margin: 2rem 2rem 2rem 0;
  display: flex;

  @media (max-width: 1050px) {
    width: 45%;
  }

  @media (max-width: 700px) {
    width: 100%;
    margin: 2rem 0;

  }

  &__containerLeft {
    width: 80%;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__containerRight {
    border-left: 1px solid gray;

    &__checkboxes {
      padding: 0.1rem .3rem;
    }

    &__lastCheckbox {
      border-top: 1px solid gray;
      margin-top: .3rem;
      padding: .1rem .3rem;
    }
  }
}
.accompanyingMaterial {
  // padding: 0 1.5rem;
  // padding-top: 4rem;
  display: flex;
  flex-flow: column;
  // justify-content: center;
  // align-items: center;
  letter-spacing: normal;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;


  &__body {
    // width: 80%;

    // @media (max-width:1200px) {
    //   width: 100%;
    // }
    &__header {
      border-bottom: 1px solid gray;
      padding: 2rem;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        margin-right: 1rem;
      }

      h2 {
        color: gray;
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &__pdfLink {
        margin: 2rem 2rem;
        border: 1px solid black;
        width: 28%;
        min-width: 300px;
        padding: 3rem;
        display: flex;
        justify-content: center;
        text-decoration: unset;
        align-items: center;

        @media (max-width: 1200px) {
          width: 40%;
          min-width: unset;
        }

        @media (max-width: 750px) {
          width: 100%;
          min-width: unset;
          margin-top: 1rem;

          &:nth-child(1) {
            margin-top: 2rem;
          }
        }

        p {
          color: black;
        }
      }
    }
  }
}
.kilianTasks {
  // padding: 0 1.5rem;
  // padding-top: 4rem;
  display: flex;
  flex-flow: column;
  // justify-content: center;
  // align-items: center;
  letter-spacing: normal;
  font-size: .8rem;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;


  &__body {
    &__header {
      border-bottom: 1px solid gray;
      padding: 2rem;
      display: flex;
      justify-content: space-between;

      &__logo {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          margin-right: 1rem;
        }

        h2 {
          color: gray;
        }
      }

      &__heading {
        margin-right: 10%;
        letter-spacing: .3rem;
        transition: margin-right .5s;
        font-size: 1.3rem;

        @media (max-width: 750px) {
          margin-right: 0;
          font-size: 1.3rem;
          letter-spacing: .1rem;
        }

        @media (max-width: 450px) {
          display: none;
        }
      }

    }

    &__pdfContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__task {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 2rem;

      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      input {
        flex: 1;
        margin: 0 1rem;
        outline: none;
        padding: 5px;

        @media (max-width: 500px) {
          width: 100%;
          margin: .5rem 0;
        }
      }

      button {
        font-size: .8rem;
        background-color: #1E98D7;
        border: none;
        outline: none;
        border-radius: 5px;
        padding: 7px 15px;
        color: white;

        @media (max-width: 500px) {
          width: 100%;
        }

      }
    }

    &__uploadsContainer {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      margin: 2rem;
      // width: 80%;
      // border: 1px solid red;

      @media (max-width:750px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

        @media (max-width:750px) {
          width: 100%;
        }

        &__files {
          margin-top: 2rem;
          border: 1px solid black;
          padding: .5rem;
          overflow: auto;
          width: 300px;
          height: 250px;

          @media (max-width:750px) {
            width: 100%;
            margin-bottom: 2rem;
          }

          &__file {
            display: flex;
            margin-top: .5rem;

            p {
              margin-left: .3rem;
            }
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

        @media (max-width:750px) {
          width: 100%;
        }

        &__links {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;

          @media (max-width:750px) {
            width: 100%;
          }

          &__pdfLink {
            border: 1px solid black;
            padding: 2rem;
            width: 300px;
            text-align: center;
            margin-bottom: 1rem;
            text-decoration: unset;
            color: black;

            @media (max-width:750px) {
              width: 100% !important;
            }


          }
        }
      }
    }
  }
}
.classAnalysis {
  padding: 1.5rem;
  padding-top: 4.5rem;
  overflow: auto;

  &__row {
    width: 100%;
  }

  &__teacher {
    width: 100%;

    &__row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      gap: 1rem;
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    min-width: 40rem;
    // border: 1px solid black;

    tr {
      th,
      td {
        font-size: 0.8rem;
        width: 8.333%;
        padding: 0.5rem;
        border-top: 1px solid black !important;
        border-left: 1px solid black !important;
        &:nth-child(4) {
          width: 16.66%;
        }
        &:nth-child(5) {
          width: 16.66%;
        }
        &:nth-child(6) {
          width: 16.66%;
        }
        &:nth-child(7) {
          width: 16.66%;
        }

        &:last-child {
          border-right: 1px solid black !important;
        }
      }

      td {
        text-align: center;
      }

      &:last-child {
        border-bottom: 1px solid black !important;
      }
    }

    // &__headings {
    //   width: 100%;

    //   &__heading {
    //     border-left: 1px solid black;
    //     border-top: 1px solid black;
    //     flex: 1;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding: 0.5rem 1rem;
    //     font-size: 0.8rem;
    //     font-weight: 600;

    //     &:last-child {
    //       border-right: 1px solid black;
    //     }
    //   }
    // }

    // &__content {
    //   width: 100%;
    //   &__row {
    //     width: 100%;
    //     display: flex;

    //     &:last-child {
    //       border-bottom: 1px solid black;
    //     }
    //     &__item {
    //       border-top: 1px solid black;
    //       border-left: 1px solid black;
    //       flex: 1;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       padding: 0.5rem 1rem;
    //       font-size: 0.8rem;

    //       &:nth-child(4) {
    //         flex: 2;
    //       }
    //       &:nth-child(5) {
    //         flex: 2;
    //       }
    //       &:nth-child(6) {
    //         flex: 2;
    //       }
    //       &:last-child {
    //         border-right: 1px solid black;
    //       }
    //     }
    //   }
    // }
  }
}

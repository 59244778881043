.signInContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;

  &__header {
    background-color: #f2f2f2;
    width: 300px;
    height: 70px;
    border-radius: 5px 5px 0 0;
    padding: 1rem;
    position: relative;

    &__logo {
      border: 1px solid grey;
      position: absolute;
      border-radius: 50%;
      padding: 1rem;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 35px;
        z-index: 1;
      }
      hr {
        position: absolute;
        width: 65px;
      }
    }
  }
  &__fields {
    background-color: #d7d7d7;
    height: auto;
    width: 300px;
    border-radius: 0 0 5px 5px;
    padding-top: 60px;
    text-align: center;
    padding-bottom: 1rem;
    p {
      color: gray;
      text-align: center;
      padding-top: 0.5rem;
      font-weight: 500;
      font-size: 1.2rem;
    }
    &__input {
      align-items: center;
      margin-bottom: 10px;
      width: 280px;
      padding: 15px;
      border: none;
      outline: none;
      border-radius: 2px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-around;
    width: 290px;
    margin-top: 8rem;
    &__button1 {
      width: 33%;
      background-color: lightgray;
      border: none;
      outline: none;
    }
    &__button2 {
      width: 33%;
      background-color: gray;
      border: none;
      outline: none;
      padding: 12px 0;
    }
    &__button3 {
      width: 33%;
      background-color: lightgray;
      border: none;
      outline: none;
      padding: 12px 0;
    }
  }
  p {
    color: gray;
    margin-top: 0.2rem;
  }
}

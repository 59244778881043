// body {
//   background-color: #cfd8dd;
// }
.homeContainer {
  padding: 1.5rem;
  padding-top: 6.5rem !important;

  &__info {
    width: 100%;
    border: 1px solid black;
    background-color: white;
    font-size: 0.8rem;
    display: flex;
    flex-flow: column;
    padding: 0.5rem 0;

    &__row {
      width: 100%;
      display: flex;
      padding: 0.5rem 1rem;
      justify-content: space-between;

      &__item {
        display: flex;
      }
    }
  }

  &__options {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem 0;
  }

  // &__top {
  //   height: 35px;
  //   border-top: 1px solid lightgray;
  //   border-radius: 5px 5px 0 0;
  // }
  &__boxes-R1 {
    display: flex;
    padding: 30px;
    color: gray;
    font-weight: 500;
    font-size: 2rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      padding: 15px;
      text-align: center;
    }

    &__leftContainer {
      background-color: white;
      width: 50%;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      @media (max-width: 768px) {
        margin-right: 7.5px;
      }

      img {
        width: 120px;
        height: 120px;
      }
    }

    &__rightContainer {
      background-color: white;
      width: 50%;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        margin-left: 7.5px;
      }

      img {
        width: 120px;
        height: 120px;
      }
    }
  }

  &__boxes-R2 {
    padding: 0 30px 30px 30px;
    color: gray;
    font-weight: 500;
    font-size: 2rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      padding: 15px;
      text-align: center;
    }

    &__container {
      background-color: white;
      width: 100%;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      img {
        width: 120px;
        height: 120px;
      }
    }
  }
}
.proofOfPractice {
  padding: 0 1.5rem;
  padding-top: 7rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  letter-spacing: normal;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;


  &__body {
    width: 80%;

    @media (max-width:1200px) {
      width: 100%;
    }

    &__heading {
      margin-bottom: .5rem;
    }

    &__tableBox {
      overflow: auto;


      &__table {
        border-collapse: collapse;
        width: 90%;
        min-width: 40rem;

        &__tableHead {
          &__row {
            &__head {
              border: 1px solid gray !important;
              text-align: start;
              font-size: .8rem;
              text-decoration: underline;
              padding: .5rem;
            }
          }
        }

        &__tableBody {
          border: 1px solid gray !important;

          &__row {
            td:nth-child(1) {
              text-decoration: underline;
              font-weight: 600;
              border-right: 1px solid gray;
              padding: .5rem;
            }

            &__details {
              font-size: .8rem;

              &__descBox {
                display: flex;
                justify-content: flex-start;

                &__orientation {
                  padding: .5rem;
                  width: 33%;

                  &:nth-last-child(1) {
                    font-weight: normal !important;
                  }

                  &__heading {
                    font-weight: 600;
                    margin-bottom: .3rem;
                  }

                  &__checkBox {
                    display: flex;
                    align-items: center;
                    margin-bottom: .3rem;

                    input {
                      margin-right: .1rem;
                    }
                  }
                }
              }
            }

            &__absenteeism {
              border-top: 1px solid gray;
              padding: .3rem .5rem;

              strong {
                text-decoration: underline;
              }

              &__greater {
                font-weight: bold;
                color: red;
              }
            }
          }
        }
      }

      &__explanation {
        width: 90%;
        min-width: 40rem;
        border: 1px solid gray;
        border-top: unset;
        padding: .5rem;
        font-size: .8rem;

        p {
          margin-top: .1rem;
        }
      }
    }

    &__signatureTable {
      margin: 1rem 0;
      overflow: auto;

      &__table {
        border-collapse: collapse;

        width: 100%;
        min-width: 40rem;

        &__tableHead {
          &__row {
            border: 1px solid gray;

            &__head {
              font-size: .8rem;
              padding: .5rem;
              font-weight: normal;
              border: 1px solid gray;

            }
          }
        }

        &__tableBody {
          &__row {
            border: 1px solid gray;

            &__details {
              width: 200px;
              min-width: 100px;
              font-size: .8rem;
              padding: .5rem;
              text-align: center;
              border: 1px solid gray;

              &:nth-last-child(4) {
                flex: 1;
                width: unset;
                min-width: 200px;
              }

              &__checkBox {
                display: flex;
                align-items: center;
                justify-content: center;

                label {
                  margin-left: .2rem;
                }
              }

              &__select {
                select {
                  width: 100%;
                  padding: 5px 0;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }

    &__competencyTable {
      margin: 1rem 0;
      border: 1px solid gray;
      overflow: auto;
      padding: .5rem;
      width: 90%;

      @media (max-width: 700px) {
        width: 100%;
      }

      &__heading {
        font-size: .8rem;
      }

      &__table {
        width: 100%;
        min-width: 40rem;

        &__tableBody {
          font-size: .8rem;

          &__row {
            &__details {
              padding-top: .2rem;

              &__checkBox {
                display: flex;
                align-items: center;

                label {
                  margin-left: .2rem;
                }
              }
            }
          }
        }
      }
    }

          &__evaluationHeading {
        font-size: .8rem;
        margin: 1rem 0;
      }


    &__evaluationTable {
      font-size: .8rem;
      margin: 1rem 0;
      overflow: auto;
      width: 90%;

      @media (max-width: 700px) {
        width: 100%;
      }


      &__tableHeading {
        text-align: center;
        border: 1px solid gray;
        border-bottom: unset;
        padding: .3rem .5rem;
        width: 100%;
        min-width: 40rem;

      }

      &__table {
        border-collapse: collapse;
        width: 100%;
        min-width: 40rem;

        &__tableBody {
          border: 1px solid gray;

          &__row {
            &__details {
              border: 1px solid gray;
              padding: .5rem .5rem 5rem .5rem;

              &__checkBox {
                display: flex;
                align-items: center;

                label {
                  margin-left: .2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
.assessmentCats {
  padding: 1.5rem;
  padding-top: 6.5rem;

  &__intro {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cats {
    display: flex;
    flex-wrap: wrap;
  }
}

.optionQuestions {
  padding: 1.5rem;
  padding-top: 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;

  &__bottom {
    display: flex;
    flex-flow: column;
    font-size: 0.8rem;
    max-width: 25rem;
    width: 100%;
    min-height: 10rem;
    display: flex;
    justify-content: center;

    label {
      margin-bottom: 0.5rem;
    }
    select {
      padding: 0.5rem;
    }
  }
}

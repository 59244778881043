.learningGoals{
    padding: 1.5rem;
    padding-top: 5.0rem;

    &__top-content{
        margin-bottom: 2rem;
        width:40rem;
        display: flex;
        justify-content: space-between;
    }
    &__table{
        table{
        width: 10rem;
        border: 1px solid black;
        border-collapse: collapse;
        th, td{
            padding: 0.5rem;
            text-align: center;
            border: 1px solid black;
            font-size: 0.5rem;
            }
        }
    }
}
.nursingPhenomena {
  // padding: 0 1.5rem;
  // padding-top: 4rem;
  display: flex;
  flex-flow: column;
  // justify-content: center;
  // align-items: center;
  letter-spacing: normal;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;

  &__body {
    // width: 80%;

    // @media (max-width:1200px) {
    //   width: 100%;
    // }
    &__header {
      border-bottom: 1px solid gray;
      padding: 2rem;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        margin-right: 1rem;
      }

      h2 {
        color: gray;
      }
    }

    &__container {
      margin: 2rem;
      font-size: 0.8rem;

      &__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-collapse: collapse;

        @media (max-width: 750px) {
          display: flex;
          flex-direction: column;
        }

        &__left {
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          @media (max-width: 750px) {
            width: 100%;
          }

          &__checkBoxes {
            border: 1px solid black;
            padding: 0.3rem;
            border-right: unset;
            width: 50%;

            &:nth-child(1n) {
              border-left: 1px solid black !important;
              border-right: 1px solid black !important;
            }

            &:nth-child(2n) {
              border-right: 1px solid black !important;
              border-left: none !important;
            }
            &:nth-child(3) {
              border-top: none;
            }
            &:nth-child(4) {
              border-top: none;
            }

            @media (max-width: 750px) {
              border-bottom: unset;
              border-right: 1px solid black;
            }

            &__container {
              display: flex;
              flex-direction: column;
            }
          }
        }

        &__right {
          display: flex;
          flex-direction: column;
          width: 50%;

          @media (max-width: 750px) {
            width: 100%;
          }

          &__checkBoxes {
            border: 1px solid black;
            padding: 0.3rem;

            &:nth-child(1) {
              border-bottom: unset;
            }

            &__container {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  &__links {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;

    &__link {
      background-color: #1e98d7;
      border-radius: 0.5rem;
      cursor: pointer;
      width: 20rem;
      height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
}

.nursingPhenomenaCheckBox {
  display: flex;
  justify-content: space-between;
  margin: 0.1rem 0.3rem 0.1rem 1rem;
  align-items: center;

  &__checkBox {
    display: flex;
    align-items: center;

    label {
      margin-left: 0.2rem;
    }
  }

  &__explanantionButton {
    a {
      background-color: #1e98d7 !important;
      outline: none;
      border: none;
      color: white;
      border-radius: 5px;
      padding: 1px 6px;
      font-size: 0.8rem;
      text-decoration: none;
    }
  }
}

.selectedOptionsBoxes {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  &__box {
    width: 20rem;
    height: 10rem;
    border: 1px solid black;

    display: flex;
    flex-flow: column;
    justify-content: center;
    gap: 1rem 0;
    position: relative;

    &__title {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
    }
    &__row {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 0 1rem;

      button {
        width: 85%;
        background-color: #1e98d7;
        border-radius: 0.25rem;
        border: none;
        outline: none;
        color: white;
        padding: 0.5rem;
      }
    }
  }
}

.reviewFormContainer {
  padding-top: 4.05rem;
  &__top {
    background-color: #788b92;
    height: 35px;
    border-top: 1px solid lightgray;
    border-radius: 5px 5px 0 0;
  }
  &__boxes-R1 {
    display: flex;
    padding: 30px 30px 15px 30px;
    color: gray;
    font-weight: 500;
    font-size: 2rem;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      padding: 15px;
      text-align: center;
    }
    &__leftContainer {
      background-color: white;
      width: 35rem;
      margin-top: 1rem;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      display: flex;
      cursor: pointer;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-right: 7.5px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
    &__rightContainer {
      background-color: white;
      width: 50%;
      height: 400px;
      display: flex;
      align-items: center;
      padding-top: 7.5rem;
      margin-left: 15px;
      flex-direction: column;
      cursor: pointer;
      @media (max-width: 768px) {
        margin-left: 7.5px;
      }
      img {
        width: 120px;
        height: 120px;
      }
      &__note {
        font-size: 1rem;
        margin-top: 5.5rem;
        align-self: flex-start;
        margin-left: 1rem;
        text-align: left;
      }
    }
  }
}

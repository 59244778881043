.signUpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
  padding-top: 6rem;

  &__header {
    background-color: #f2f2f2;
    width: 300px;
    height: 70px;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 1rem;
  }
  &__fields {
    background-color: #d7d7d7;
    // height: 360px;
    width: 300px;
    border-radius: 0 0 5px 5px;
    padding-top: 60px;
    padding-bottom: 60px;
    // text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;

    form {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
    p {
      color: gray;
      text-align: center;
      padding-top: 0.5rem;
      font-weight: 500;
      font-size: 1.2rem;
    }
    &__input {
      align-items: center;
      margin-bottom: 10px;
      width: 280px;
      padding: 15px;
      border: none;
      outline: none;
      border-radius: 2px;
      background-color: white;
    }

    .uploadBtn {
      width: 100%;
      max-width: 280px;
      border: none;
      outline: none;
      padding: 12.5px;
      background-color: #1e98d7;
      color: white;
      border-radius: 2px;
    }
  }

  .label {
    // text-align: left !important;
  }
}

.blockedSpanLink {
  display: block;
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.input__group {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .label {
    width: 280px;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 0.6rem;
    margin-bottom: 0.25rem;
  }
}

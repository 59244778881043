.uploadFolders {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  max-width: 45rem;
}

.uploadFolder {
  min-width: 20rem;
  margin-top: 1rem;
  height: 4rem;
  border: 1px solid black;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  cursor: pointer;
  i {
    font-size: 1.5rem;
  }
}

.uploads {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;

  h3 {
    text-transform: capitalize;
    color: #00c2ff;
  }

  &__upload {
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      display: none;
    }
    i {
      font-size: 6rem;
      cursor: pointer;
      color: #00c2ff;
    }
  }

  &__loader {
    width: 30rem;
    margin: 0 0 1rem 0;
  }

  &__uploads {
    width: 30rem;
    height: 30rem;
    overflow-y: auto;
    border: 1px solid #00c2ff;
    border-radius: 1rem;
    padding: 1rem;

    &__file {
      width: 100%;
      display: flex;
      padding: 0.5rem 1rem;
      gap: 1rem;
      align-items: center;
      cursor: pointer;
    }
  }
}

.loader2 {
  width: 0;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: #00c2ff;
  box-shadow: 0 0 10px rgba(0, 195, 255, 0.5);
  box-sizing: border-box;
  animation: animFw 8s linear infinite;
}
.loader2::after,
.loader2::before {
  content: "";
  width: 10px;
  height: 1px;
  background: #00c2ff;
  position: absolute;
  top: 9px;
  right: -2px;
  opacity: 0;
  transform: rotate(-45deg) translateX(0px);
  box-sizing: border-box;
  animation: coli1 0.3s linear infinite;
}
.loader2::before {
  top: -4px;
  transform: rotate(45deg);
  animation: coli2 0.3s linear infinite;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes coli1 {
  0% {
    transform: rotate(-45deg) translateX(0px);
    opacity: 0.7;
  }
  100% {
    transform: rotate(-45deg) translateX(-45px);
    opacity: 0;
  }
}

@keyframes coli2 {
  0% {
    transform: rotate(45deg) translateX(0px);
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) translateX(-45px);
    opacity: 0.7;
  }
}

.listingOptions {
  padding: 1.5rem;
  padding-top: 6.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  &__arthrosisTopContent {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-size: 1.2rem;
  }
}

.listingOptionsPractice {
  padding-top: 6rem;
  margin: 0 2.5rem;
  margin-bottom: -6rem;
}

.listingOptionsPractice2 {
  margin: 0 2.5rem;
  margin-bottom: -6rem;

}
.competencyProfile {
  padding: 1.5rem;
  padding-top: 5.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;

  &__message {
    width: 100%;
    padding: 0.5rem;
    background-color: #f2f2f2;
    text-align: center;
  }

  &__box {
    width: 15rem;
    height: 5rem;
    border-radius: 1rem;
    color: white;
    background-color: #217ea5;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem;
    text-align: center;
    span {
      display: block;
    }
  }

  h4 {
    text-align: center;
  }

  &__mid {
    margin-top: -1rem;
    h4 {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__circle {
    width: 25rem;
    height: 25rem;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    img {
      position: absolute;
      width: 23rem;
      height: 27rem;
      top: -3.5rem;
      left: 1rem;
      margin-bottom: -5rem;
    }

    &__contemporay {
      position: absolute;
      left: 1rem;
      top: 6rem;
      border: 1px solid black;
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      max-width: 10rem;
      border-radius: 1rem;
      transform: rotate(295deg);
      background-color: #e7e7e7;
    }
    &__significance {
      position: absolute;
      right: 1rem;
      top: 6rem;
      border: 1px solid black;
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      max-width: 10rem;
      border-radius: 1rem;
      transform: rotate(-295deg);
      background-color: #e7e7e7;
    }
    &__exem {
      position: absolute;
      left: 50%;
      bottom: 2rem;
      transform: translateX(-50%);
      border: 1px solid black;
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      max-width: 10rem;
      border-radius: 1rem;
      background-color: #e7e7e7;
    }

    &__triangleContent {
      width: 20rem;
      position: absolute;
      top: 2rem;
      height: 17.5rem;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      &__item {
        width: 100%;
        display: flex;
        padding: 0.5rem 0;
        font-size: 0.8rem;
        justify-content: center;
      }
    }
  }

  &__triangle {
    width: 0;
    height: 0;
    border-left: 8rem solid transparent;
    border-right: 8rem solid transparent;
    border-bottom: 22rem solid #000;
    border-bottom-color: #000; /* Set the bottom border color to black */
    // border-width: 1px;
  }

  &__results {
    max-width: 80rem;
    width: 100%;
    height: 27.5rem;

    display: flex;
    justify-content: space-between;

    &:first-child {
      margin-top: 6rem;
    }

    &-1 {
      margin-top: 3rem;
    }
    &-2 {
      height: 6rem;
    }

    &__mid {
      &-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 5rem;
      }
    }
    &__left {
      position: relative;
      padding-top: 5rem;
      .arrow {
        max-width: 12rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 12.5rem;
      }

      display: flex;
      flex-flow: column;
      gap: 1rem;

      &-2 {
        width: 15rem;
        height: 15rem;
        padding: 0;
        display: flex;
        flex-flow: row;
        justify-content: center;
        padding: 2rem;
      }
    }

    &__right {
      padding-top: 5rem;
      position: relative;
      .arrow {
        max-width: 12rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 12.5rem;
        top: 0;
      }

      .arrow-2way {
        color: #ea8139 !important;
      }
      display: flex;
      flex-flow: column;
      gap: 1rem;

      &-2 {
        width: 15rem;
        height: 15rem;
        padding: 0;
        display: flex;
        flex-flow: row;
        justify-content: center;
        padding: 2rem;
      }

      .competencyProfile__box {
        background-color: #ea8139 !important;
        margin-bottom: 3rem;
      }
    }

    .arrow-2way {
      width: 25rem;
      height: 30px;
      display: flex;
      color: #217ea5;
      background: linear-gradient(currentColor 0 0) center/calc(100% - 30px)
          10px,
        conic-gradient(
            from -136deg at right,
            #0000,
            currentColor 1deg 90deg,
            #0000 91deg
          )
          right/15px 100%,
        conic-gradient(
            from 44deg at left,
            #0000,
            currentColor 1deg 90deg,
            #0000 91deg
          )
          left / 15px 100%;
      background-repeat: no-repeat;
    }
  }
}

.arrow-1way {
  width: 4rem;
  height: 30px;
  display: flex;
  color: #217ea5;
  background: linear-gradient(currentColor 0 0) center/calc(100% - 30px) 10px,
    right/15px 100%,
    conic-gradient(
        from 44deg at left,
        #0000,
        currentColor 1deg 90deg,
        #0000 91deg
      )
      left / 15px 100%;
  background-repeat: no-repeat;
}

.arrow-up {
  transform: rotate(90deg);
}
.arrow-down {
  transform: rotate(270deg);
}

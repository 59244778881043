.dashboard {
  width: 100%;
  height: 100%;

  &__tables {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-between;

    &__table {
      width: 23%;
      height: 90%;
      background-color: #eceff7;
      border-radius: 0.5rem;
      padding: 1rem;
      position: relative;

      &__add {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }

      &__message {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__headings {
        width: 100%;
        margin-top: 0.5rem;

        &__heading {
          font-size: 0.8rem;
        }
      }

      &__item {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 0.8rem;
        background-color: #f7f7f7;
        padding: 0.25rem 0;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          background-color: $THEME_COLOR;
          font-size: 0.7rem;
          padding: 0.15rem;
          border: none;
          outline: none;
          border-radius: 0.25rem;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

.dashboardAddModal {
  form {
    margin-top: 1rem;
    text-align: center;
  }
}

.competencyCatBox {
  width: 20rem;
  height: 12.5rem;
  border: 1px solid black;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.addForm-AContainer {
  padding-top: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: white;
  min-height: 100vh;
  @media (max-width: 450px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &__selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    @media (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    select {
      width: 300px;
      padding: 8px;
      border-radius: 3px;
      background-color: lightgray;
      font-size: large;
      color: gray;
      outline: none;
      border: none;
    }
  }
  &__textarea {
    margin-top: 1rem;
    textarea {
      padding: 1rem;
      width: 60%;
      height: 400px;
      border: 3px solid gray;
      font-size: large;
      color: gray;
      @media (max-width: 1100px) {
      width: 70%;
      }
      @media (max-width: 768px) {
      width: 100%;
      }
    }
  }
}
.evaluationContainer {
  padding-top: 4.05rem;
  &__top {
    background-color: #788b92;
    height: 35px;
    border-top: 1px solid lightgray;
    border-radius: 5px 5px 0 0;
  }
  &__boxes-R1 {
    display: flex;
    padding: 30px;
    color: gray;
    font-weight: 500;
    font-size: 2rem;
    cursor: pointer;
    @media (max-width: 768px) {
      font-size: 1.5rem;
      padding: 15px;
      text-align: center;
    }
    &__leftContainer {
      background-color: white;
      width: 50%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-right: 7.5px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
    &__rightContainer {
      background-color: white;
      width: 50%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      @media (max-width: 768px) {
        margin-left: 7.5px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
}

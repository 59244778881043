.commentsContainer {
  padding: 4.1rem 2rem;
  background-color: white;
  min-height: 100vh;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 450px) {
    padding: 4.2rem 1rem;
  }
  &__displayContainer {
    &__heading {
      // position: fixed;
      // background-color: lightgray;
      // border-radius: 3px;
      // width: 450px;
      // border: 1px solid gray;
      // z-index: 3;
      // @media (max-width: 500px) {
      //   width: 300px;
      // }
    }
    &__box {
      border: 1px solid gray;
      border-radius: 3px;
      height: auto;
      width: 450px;
      margin: 0.5rem 0 1.5rem 0;
      padding: 5px;
      background-color: lightgray;
      @media (max-width: 500px) {
        width: 300px;
      }
      &__username {
        font-size: 0.7rem;
        font-weight: bold;
      }
      &__comment {
        font-size: 0.9rem;
        margin-top: 0.3rem;
        margin-inline-start: 0.4rem;
      }
      &__time {
        font-size: 0.6rem;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &__addComment {
    border-radius: 3px;
    width: 450px;
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 2;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.1rem;
    border: 1px solid gray;
    mask-image: linear-gradient(to-bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    @media (max-width: 500px) {
      width: 300px;
    }
    &__input {
      width: 88%;
      padding: 8px;
      outline: none;
      border: none;
      background-color: lightgray;
      color: gray;
      height: 100%;
      border-radius: 3px 0 0 3px;
    }
    &__button {
      width: 12%;
      height: 100%;
      outline: none;
      border: none;
      background-color: gray;
      color: lightgray;
      cursor: pointer;
    }
  }
}

.optionQuestionAnswers {
  padding: 1.5rem;
  padding-top: 7rem;

  &__confirm {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    input {
      margin-right: 0.5rem;
    }
  }
  &__box {
    width: 100%;
    font-size: 0.8rem;
    &__intro {
      width: 100%;
      text-align: center;
      border: 1px solid gray;
      padding: 0.5rem;
    }

    &__items {
      font-size: 0.8rem;
      &__item {
        display: flex;

        &__left {
          width: 42%;
          border: 1px solid gray;
          border-top: none;
          border-right: none;
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__middle {
          width: 55%;
          border: 1px solid gray;
          border-top: none;
          border-right: none;
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          text-align: justify;
          text-justify: inter-word;
        }
        &__right {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid gray;
          border-top: none;
        }
      }
    }
  }
}

.skillsIV {
  padding: 0 1.5rem;
  padding-top: 7rem;
  // display: flex;
  // flex-flow: column;
  // justify-content: center;
  // align-items: center;
  letter-spacing: normal;
  font-size: .8rem;
  // background-color: white;
  // height: 100%;
  // min-height: 100vh;

&__header {
  text-align: center;
  font-size: 1.2rem;
}
  &__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
    // width: 80%;
    
    @media (max-width: 750px) {
      display: flex;
      justify-content: center;
    }
  }
}
.review-AContainer {
  padding-top: 4.5rem;
  background-color: white;
  min-height: 100vh ;
  padding-bottom: 3rem;
  @media (max-width:550px) {
    text-align: center;
  }
  &__image {
    width: 150px;
    height: 150px;
    margin: 1rem;
    background-color: lightgray;
  }
  &__form {
    padding: 0 2rem;
    text-align: center;
    color: gray;
    margin-top: -7rem;
    @media (max-width:550px) {
    margin-top: 0;
    padding: 0 1rem;
    }
    h2 {
      font-weight: 500;
      font-size: 2.5rem;
      margin-bottom: 4rem;
    }
    &__date {
      display: flex;
      justify-content: flex-end;
      font-weight: bold;
    }
    &__container1 {
      border: 3px solid gray;
      display: flex;
      justify-content: flex-start;
      padding: 1rem;
      font-weight: 500;
      font-size: 1.5rem;
      margin-top: .5rem;
      height: 200px;
    }
    &__container2 {
      border: 3px solid gray;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 1rem;
      font-weight: 500;
      font-size: 1.5rem;
      height: 400px;
      &__footer {
        align-self: flex-end;
        margin-top: 250px;
        font-size: 1rem;
        font-weight: 500;
      }
    }
      &__container3 {
        border: 3px solid gray;
        display: flex;
        justify-content: flex-start;
        padding: 1rem;
        font-weight: 500;
        font-size: 1.5rem;
        height: 150px;
    }
  }
}
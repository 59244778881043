.simpleOptions {
  padding: 1.5rem;
  padding-top: 5.5rem;

  &__topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__grades {
      border: 1px solid gray;
      padding: 0.5rem;
    }
  }

  &__options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    &__item {
      max-width: 20rem;
      width: 100%;
      padding: 1rem 1rem;
      border: 1px solid gray;
      cursor: pointer;
    }
  }
}

.talksform {
  padding: 1.5rem;
  padding-top: 5.5rem;

  h5 {
    text-align: center;
  }

  &__row {
    font-size: 0.8rem;
    margin-top: 1rem;
    display: flex;

    span {
      display: flex;
      align-items: center;

      input {
        margin-right: 0.5rem;
      }

      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  &__table {
    width: 100%;
    margin-top: 2rem;
    font-size: 0.8rem;

    &__intro {
      width: 100%;
      display: flex;

      &__left {
        width: 50%;
        padding: 1rem;
        border: 1px solid gray;

        span {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:not(:first-child) {
            margin-top: 0.5rem;
          }

          input {
            width: 3rem;
            &:not(:first-child) {
              margin-left: 0.5rem;
            }
          }

          // input[type="date"] {
          //   padding: 0.25rem;
          // }
        }
      }

      &__right {
        width: 50%;
        padding: 1rem;
        border: 1px solid gray;
        border-left: none;

        span {
          display: flex;
          justify-content: space-between;

          &:not(:first-child) {
            margin-top: 0.5rem;
          }
        }
      }
    }

    &__heading {
      width: 100%;
      padding: 0.5rem 1rem;
      background-color: #d7d7d7;
    }

    &__second {
      width: 100%;
      padding: 0.5rem 1rem;
      border: 1px solid gray;

      &__row {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        select {
          padding: 0.25rem;
          width: 15rem;
        }

        &__text {
          display: flex;
          flex-flow: column;
        }
      }
    }

    &__double {
      width: 100%;
      display: flex;

      &__left {
        width: 50%;
        padding: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border: 1px solid gray;
        min-height: 7.5rem;
        text-decoration: none;
        color: black;
        cursor: pointer;

        a {
          color: black;
          text-decoration: unset;
          cursor: pointer;
        }

        &__row {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &__item {
            max-width: 12rem;
            padding: 1rem;
            background-color: #1e98d7;
            border-radius: 0.5rem;
            color: white;
          }

          margin-top: 1rem;
        }
      }

      &__right {
        width: 50%;
        padding: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border: 1px solid gray;
        min-height: 7.5rem;

        a {
          color: black;
          text-decoration: unset;
          cursor: pointer;
        }

        &__top {
          height: 85%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__bottom {
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

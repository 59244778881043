.supervisorContainer {
  padding-top: 6.2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__searchBar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;

    input {
      background-color: lightgray;
    }
  }

  &__addButtonContainer {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    gap: 0 1rem;
    margin-top: 1rem;

    @media (max-width: 1200px) {
      width: 100%;
      padding: 0 2rem;
    }

    button {
      border: 1px solid black;
      font-size: 0.8rem;
      padding: 7px 10px;
      border-radius: 3px;
      background-color: unset;
      outline: none;
      font-size: 0.8rem;
      cursor: pointer;
      &:last-child {
        background-color: #1e98d7;
        color: white;
        border: none;
      }
    }
  }

  &__container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    font-size: 0.8rem;

    @media (max-width: 1200px) {
      width: 100%;
      padding: 0 2rem;
    }

    &__classes {
      display: flex;
      flex-direction: column;

      &__heading {
        display: flex;
        align-items: center;
        background-color: #fafafa;
        padding: 1rem 0;
        border-bottom: 1px solid black;

        &__name {
          text-align: center;
          width: 50%;
        }

        &__numberOfStudent {
          text-align: center;
          width: 50%;
        }
      }

      &__data {
        display: flex;
        align-items: center;
        background-color: #fafafa;
        padding: 1rem 0;
        border-bottom: 1px solid black;

        &__name {
          text-align: center;
          width: 50%;
        }

        &__numberOfStudent {
          text-align: center;
          width: 50%;
        }
      }
    }
  }

  &__settings {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;

    &__card {
      height: 180px;
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: lightgray;
      margin-top: 5rem;

      p {
        color: gray;
        font-size: 2.5rem;
        font-weight: 500;
      }

      img {
        height: 70px;
        width: 70px;
      }
    }
  }

  &__modal {
    background-color: #ffffff !important;
    outline: none;
    input {
      border: 1px solid gray;
      border-radius: 3px;
    }

    button {
      border: 1px solid gray;
      font-size: 0.8rem;
      padding: 10px 15px;
      border-radius: 3px;
      background-color: #1e98d7;
      outline: none;
      font-size: 0.8rem;
      color: white;
      margin-top: 0.5rem;
      width: 280px;
    }
  }
}

.primaryBtn {
  font-size: 0.8rem;
  padding: 7px 10px;
  border-radius: 3px;
  outline: none;
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #1e98d7;
  color: white;
  border: none;
}
